export default () => {
  let swiper = new Swiper("#js-swiperProducts", {
    slidesPerView: 1,
    speed: 1000,
    parallax: true,
    autoHeight: true,
    effect: "slide",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  })
}