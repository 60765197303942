export default () => {
  new Swiper("#js-swiperWorks", {
    slidesPerView: "auto",
    spaceBetween: 16,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
    },
    breakpoints: {
      768: { slidesPerView: 2 }
    }
  })
}
