import offset from "./../utility/get-offset"

export default () => {
  const scaleBg = document.getElementById("js-scaleBg")
  let defalutSize = 0
  function setDefaultBackgroundSize() {
    defalutSize = window.innerHeight * 1.2
    scaleBg.style.backgroundSize = `auto ${defalutSize}px`
  }
  function setNewBackgroundSize() {
    const top = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
    const setSize = defalutSize - (top * 0.1)
    if(setSize > window.innerHeight) {
      scaleBg.style.backgroundSize = `auto ${setSize}px`
    }
  }
  const observer = new IntersectionObserver(event=> {
    if(event[0].isIntersecting) {
      window.addEventListener("scroll", setNewBackgroundSize) 
    } else {
      window.removeEventListener('scroll', setNewBackgroundSize)
    }
  })

  window.addEventListener('DOMContentLoaded', setDefaultBackgroundSize)
  const userAgent = navigator.userAgent
  if(userAgent.indexOf("iPhone") >= 0 || userAgent.indexOf("iPad") >= 0 || userAgent.indexOf("Android") >= 0) {
    window.addEventListener('orientationchange', setDefaultBackgroundSize)
  } else {
    window.addEventListener('resize', setDefaultBackgroundSize)
  }
    
  observer.observe(document.querySelector('.hero'))

  const menu = document.getElementById("js-menu")
  const menuBtn = document.getElementById("js-menuBtn")
  menu.addEventListener("click", function (event) {
    if (window.matchMedia("(max-width:768px)").matches) {
      if (!this.classList.contains("is-active")) {
        this.classList.add("is-active")
        menuBtn.classList.add("is-active")
      } else {
        this.classList.remove("is-active")
        menuBtn.classList.remove("is-active")
        event.stopPropagation()
      }
    }
  })
}