import offset from "./../utility/get-offset"

export default () => {
  const read = document.getElementById("js-read")
  let readShowBorder = offset(document.getElementById("js-readShowBorder"))
  
  window.addEventListener("scroll", () => {
    const windowTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
    if (windowTop > readShowBorder.top) {
      read.classList.add("is-active")
    } else if (windowTop < readShowBorder.top) {
      read.classList.remove("is-active")
    }
  })
}